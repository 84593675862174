import React from 'react';
import './Edge.css';

const HorizontalEdge = ({ x1, y1, x2, y2 }) => {
    //const midY = (y1 + y2) / 2;
    const midX = x2 - 200;
    const baseCurveRadius = 50;
    const baseCurveRadiusX = Math.min(baseCurveRadius, Math.abs(x2 - x1)/2);
    const baseCurveRadiusY = Math.min(baseCurveRadius, Math.abs(y2 - y1)/2);

    // Determine if the curve should be positive or negative based on the end nodes' positions
    const curveRadiusX = x1 < x2 ? baseCurveRadiusX : -baseCurveRadiusX;
    const curveRadiusY = y1 < y2 ? baseCurveRadiusY : -baseCurveRadiusY;

    // Create a path with two slightly curved angles using cubic B�zier curves
    const pathD = `
        M ${x1} ${y1}
        L ${midX - curveRadiusX} ${y1}
        C ${midX} ${y1}, ${midX} ${y1}, ${midX} ${y1 + curveRadiusY}
        L ${midX} ${y2 - curveRadiusY}
        C ${midX} ${y2}, ${midX} ${y2}, ${midX + curveRadiusX} ${y2}
        L ${x2} ${y2}
    `;

    return (
        <>
            <path
                d={pathD}
                className="edge-path" // Add the class name
                strokeWidth="6"
                fill="none"
                strokeLinecap="square"
                markerEnd="url(#arrow)"
            />
            <path
                d={pathD}
                className="edge-glow" // Add the class name
                strokeWidth="10" // A larger stroke width to create the glow effect
                fill="none"
                strokeLinecap="square"
            />
        </>
    );
};

export default HorizontalEdge;
