import * as dagre from 'dagre';

const applySugiyamaLayout = (nodes, edges, isHorizontal=false) => {
    const g = new dagre.graphlib.Graph();

    g.setGraph({
        rankdir: isHorizontal ? 'LR' : 'BT',
        ranksep: 200, // Increase the horizontal spacing between node ranks
        nodesep: 100,  // Increase the vertical spacing between adjacent nodes in the same rank
    });
    g.setDefaultEdgeLabel(() => ({}));

    nodes.forEach((node) => { 
        g.setNode(node.identity, { name: node.properties.name, width: 200, height: 70 });
    });

    edges.forEach((edge) => {
        g.setEdge(edge.start, edge.end);
    });

    dagre.layout(g);

    // Add some randomness to the node positions
    const updatedNodes = nodes.map((node) => {
        const layoutNode = g.node(node.identity);
        const randX = (Math.random() - 0.5) * 0; // Adjust this value to control the amount of randomness
        const randY = (Math.random() - 0.5) * 0;
        return {
            ...node.properties,
            x: layoutNode.x - (layoutNode.width / 2) + randX,
            y: layoutNode.y - (layoutNode.height / 2) + randY,
            nid: node.identity
        };
    });

    // Update the edges based on the new node positions
    const updatedEdges = edges.map((edge) => {
        const x1 = updatedNodes.find(n => n.nid === edge.start).x + 100;
        const y1 = updatedNodes.find(n => n.nid === edge.start).y + 35;
        const x2 = updatedNodes.find(n => n.nid === edge.end).x + 100;
        const y2 = updatedNodes.find(n => n.nid === edge.end).y + 35;
        return {
            ...edge,
            x1,
            y1,
            x2,
            y2,
        };
    });

    return { updatedNodes, updatedEdges };
};

export default applySugiyamaLayout;
