// App.js
import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import * as dagre from 'dagre';
import Node from './graph/Node';
import Edge from './graph/Edge';
import HorizontalEdge from './graph/HorizontalEdge';
import { FaDiscord, FaTwitter, FaInstagram } from 'react-icons/fa';
import applySugiyamaLayout from './utils/GraphDrawing';
import data_left from './sample_graph_left.json';
import data_right from './sample_graph_right.json';
import Confetti from 'react-confetti';

function App() {
    const [viewPortSize, setViewPortSize] = React.useState({ w: '100vw', h: '100vh' });
    const confirmRef = useRef(null);
    const [email, setEmail] = useState('');
    const [sent, setSent] = useState(false);
    const [confetti, setConfetti] = useState(false);
    const [isHorizontal, setIsHorizontal] = useState(false);
    const [nodes_left, setNodes_left] = useState([]);
    const [edges_left, setEdges_left] = useState([]);
    const [nodes_right, setNodes_right] = useState([]);
    const [edges_right, setEdges_right] = useState([]);

    const drawGraph_left = (data) => {
        const { updatedNodes, updatedEdges } = applySugiyamaLayout(data.nodes, data.edges, isHorizontal);

        setNodes_left(updatedNodes);
        setEdges_left(updatedEdges);
    };  

    const drawGraph_right = (data) => {
        const { updatedNodes, updatedEdges } = applySugiyamaLayout(data.nodes, data.edges, isHorizontal);

        setNodes_right(updatedNodes);
        setEdges_right(updatedEdges);
    };  

    useEffect(() => {
        drawGraph_left(data_left);
        drawGraph_right(data_right);
    }, []);

    const handleSubmit = async (event) => {

        event.preventDefault();
        try {
            const response = await fetch('/api/emails', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setEmail('');
            setSent(true);
            setConfetti(true);
            setTimeout(() => setConfetti(false), 10000);
        } catch (error) {
            console.error('Something went wrong!', error);
        }
    };

    return (

        <div className="outerContainerStyles">

            <svg className="svgStyles svgStyles_left">
                {isHorizontal
                    ? edges_left.map((edge) => (<HorizontalEdge {...edge} />))
                    : edges_left.map((edge) => (<Edge {...edge} />))
                }
                <path d="M 700 1385 L 700 10000" class="edge-path" stroke-width="6" fill="none" stroke-linecap="square" marker-end="url(#arrow)"></path>
            </svg>

            <div className="graphStyles graphStyles_left">
                {nodes_left.map((node) => (
                    <Node id={node.id} status={"active"} {...node} />
                ))}
            </div>

            <svg className="svgStyles svgStyles_right">
                {isHorizontal
                    ? edges_right.map((edge) => (<HorizontalEdge {...edge} />))
                    : edges_right.map((edge) => (<Edge {...edge} />))
                }
                <path d="M 100 0 L 100 -1000" class="edge-path" stroke-width="6" fill="none" stroke-linecap="square" marker-end="url(#arrow)"></path>
                <path d="M 100 1115 L 100 10000" class="edge-path" stroke-width="6" fill="none" stroke-linecap="square" marker-end="url(#arrow)"></path>
                <path d="M 400 1115 L 400 10000" class="edge-path" stroke-width="6" fill="none" stroke-linecap="square" marker-end="url(#arrow)"></path>
                <path d="M 700 1115 L 700 10000" class="edge-path" stroke-width="6" fill="none" stroke-linecap="square" marker-end="url(#arrow)"></path>
            </svg>

            <div className="graphStyles graphStyles_right">
                {nodes_right.map((node) => (
                    <Node id={node.id} status={"active"} {...node} />
                ))}
            </div>

            <div className="container">
                <div className="floater" ref={confirmRef}>
                    <img src='/logo_sm.png' className='tako-logo' alt='your description here' />
                    <h1>Launching Soon</h1>
                    <span>A structured and organized approach to learning various knowledge and skills.</span>
                    {confetti && confirmRef.current && (
                        <Confetti
                            numberOfPieces={300}
                            run={true}
                            recycle={false}
                            confettiSource={{
                                x: confirmRef.current.offsetLeft,
                                y: confirmRef.current.offsetTop,
                                w: confirmRef.current.offsetWidth,
                                h: confirmRef.current.offsetHeight,
                            }}
                        />
                    )}
                    {sent
                        ? <span className="confirmation-msg">See you soon!</span>
                        : <form onSubmit={handleSubmit}>
                            <input
                                type="email"
                                name="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <button type="submit">Notify Me</button>
                        </form>
                    }

                    <div className="social-icons">
                        {/* Replace with your actual social network icons */}
                        <a href="https://discord.gg/UPB4yEttrZ"><FaDiscord size={32} /></a>
                        <a href="https://twitter.com/Takomori_offic"><FaTwitter size={32} /></a>
                        <a href="https://www.instagram.com/takomori_official/"><FaInstagram size={32} /></a>
                    </div>
                    <footer>&copy; 2023 by Takomori.com</footer>
                </div>
            </div>

        </div>
    );
}

export default App;
