import React, { useState } from 'react';
import './Node.css';

const Node = ({ id, name, x, y, subject, tags, status }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const colors = ["#666666", "#e67300", "#63ad55", "#566cae"];
    const shadow_colors = ["#4d4d4d", "#b35900", "#528e46", "#475990"];

    const tagElements = tags.map((tag, index) => (
        <span key={index} className="tags" style={{ backgroundColor: shadow_colors[status]}}>
            {tag}
        </span>
    ));

    let boxShadowValue = `0px 6px 0px 0px ${shadow_colors[status]}, 0px 6px 10px 5px rgba(0, 0, 0, 0.1)`;
    let transformValue = 'none';
    let cursorValue = 'pointer';

    if (isHovered) {
        boxShadowValue = `0px 9px 0px 0px ${shadow_colors[status]}, 0px 9px 10px 5px rgba(0, 0, 0, 0.1)`;
        transformValue = 'translateY(-3px)';
    }

    if (isActive) {
        boxShadowValue = `0px 3px 0px 0px ${shadow_colors[status]}, 0px 3px 10px 5px rgba(0, 0, 0, 0.1)`;
        transformValue = 'translateY(3px)';
        cursorValue = 'pointer';
    }

    return (
        <div className="node" style={{ left: x, top: y }}>
            <div
                className="node-container"
                style={{
                    backgroundColor: colors[status],
                    boxShadow: boxShadowValue,
                    transform: transformValue,
                    cursor: cursorValue
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onMouseDown={() => setIsActive(true)}
                onMouseUp={() => setIsActive(false)}
                onBlur={() => setIsActive(false)}
            >
                <div className="node-content">
                    <div className="node-label">
                        <div className="topic-name">{name}</div>
                        <div>{tagElements}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Node;
